import { Navigation, Pagination, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useParams } from "react-router-dom";

const ImageSwiper = (props) => {
  const { data } = props;
  const { imgId } = useParams();
  return (
    <Swiper
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={10}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      initialSlide={Number(imgId) - 1}
    >
      {data.map((e) => {
        return (
          <SwiperSlide key={e.id}>
            <div className="!flex !justify-center !items-center lg:h-[95vh] h-[50vh]">
              <img
                src={e.galleryImg}
                alt=""
                className="max-h-full max-w-full my-auto select-none opacity-80 object-contain min-h-[400px] lg:min-w-[500px] min-w-[300px]"
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ImageSwiper;
