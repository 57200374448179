import img1 from "../../../img/programs/school.png";
import img2 from "../../../img/programs/Art and Handwriting.png";
import img3 from "../../../img/programs/creative.png";
import img4 from "../../../img/programs/personal development program.png";
import img5 from "../../../img/programs/ulc monthly events.png";

export const data = [
  {
    id: 1,
    url: "personal-development-program",
    title: "Personal Development Program (PDP)",
    dis: "This program covers activities that develop personal growth, hidden talents, and potential, enhance the quality of life and contribute to the realization of dreams and aspirations. It includes how to develop the personality of your own, public speaking, the formation of a youth club, team building, prepare a CV, Profile, report, and do presentation. We have been providing PDP to students from Grade 5 to Bachelors level since our establishment.",
    img: img4,
  },
  {
    id: 2,
    url: "school-college-program",
    title: "School/College Program",
    dis:
      "School/College Program is an initiative of the Utsah Learning Center, where we partner and work in collaboration with the different educational institutions by providing training facilities to students and teachers. We visited schools and colleges and provided training to students from Grade 1 to Bachelors level. We had meetings and interactions with the school/college Management Committee, students, teachers, and sometimes with parents too. The training on personal development, public speaking, debate, organize events, develop presentation skills, and develop confidence and creativity were conducted." +
      " ULC's first projects were with Apollo International College from 2015 until now for their 1st-semester students every year. Similarly, we continued with Excel college, Imperial Business college, CAMAD college, IMS college in the year 2019. With school, we worked in collaboration with Maha Manjushree-Sitapaila, Jyoti Academy-Sitapaila, Future Star English School-Dallu, Global Pathshala-Gathaghar, and Kathmandu Valley- Chauni.",
    img: img1,
  },
  {
    id: 3,
    url: "presentation-personal-development",
    title: "Presentation/Public Speaking Training",
    dis: "This training provides basic and advanced public speaking and presentation skills training to those who are interested in developing their confidence to speak in front of the mass. We have provided basic radio and television anchoring and production skill to Bachelors student of Greenfield National College in the year 2016. We train anchors, reporters, and encourage them to apply in the various media companies in the country.  For the first time, we did four months, one full semester Training on 'Public Speaking and Presentation' for students of the 2nd Semester of Imperial Business School in the year 2019.",
    img: img4,
  },
  {
    id: 4,
    url: "creative-competition-seminars",
    title: "Creative competition, conferences, seminars & workshops",
    dis: "ULC organizes Creative Competitions in collaboration with a different partner organization to provide open platforms for students from far and wide who look for creative challenges, and to showcase their talents. We also organize conferences, seminars, and workshops in partnership with different stakeholders, clients, and organizations. They are two projects below targeting 2020-2021. I. Public Speaking Online Exhibition Program Understanding the current scenario of the youth of Nepal, ULC plans to give a platform to young people by organizing the online Public Speaking Exhibition. II. YOUNG BUSINESS INNOVATION 2020 Due to COVID-19, we aim to run online Idea Pitching Competition to Bachelor students) will be featured in Youth in Entrepreneurship show via youtube channel. We will organize this competition in partnership with a different educational institution, corporate house, and business organization.",
    img: img3,
  },
  {
    id: 5,
    url: "arts-handwriting",
    title: "Arts/Handwriting Training",
    dis: "ULC provides Basic Art and handwriting courses to young people every Saturday and holidays with the facilitation of a team of experts from this field. I used to draw because it gave me happiness. So, my mother enrolled me at ULC Art Class every Saturday in 2017, I have learned a lot and also won inter-school Art competition Maya Singh-Grade 7. ",
    img: img2,
  },
  {
    id: 6,
    url: "ulc-monthly-events",
    title: "ULC Monthly Events",
    dis: "ULC launched its first Monthly events on 6th May 2017, inviting different guest speakers and experts from various fields to interact with our alumni and students. Every month a new speaker is invited, and our trainees organize the whole event. This is a platform created for our trainees to showcase their talent and also learn the practical practice of what they learn from us. They learn to do MC, make scripts, program schedules, planning events, and develop their confidence by interacting with guest speakers.",
    img: img5,
  },
];
