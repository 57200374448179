import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-gradient-to-r from-[#0000FFCF] to-[#0000CBFC]">
      <div className="flex flex-col ulcProgramConent w-full forBgImg ">
        <div className="min-h-[40vh] sm:px-6 lg:px-8  text-white">
          <div className="grid grid-cols-1  sm:grid-cols-1 lg:grid-cols-3">
            <div className=" flex justify-center items-center lg:flex-col flex-row py-2">
              <div className="cursor-pointer min-w-[300px] text-center font-semibold tracking-wide m-auto md:text-3xl text-xl">
                UTSAH <br /> LEARNING CENTER
              </div>
            </div>
            <div className=" flex md:justify-center md:items-center items-center lg:flex-col flex-row py-2 ">
              <div className="col-md-4 col-lg-3 mb-2 md:mb-4 flex md:items-start items-center md:gap-3 md:text-base flex-col p-6 m-auto">
                <h2 className="fw-bold ml-2 text-xl mb-1"> ULC Program</h2>
                <Link
                  to={"/ulcprograms/arts-handwriting"}
                  className="flex gap-1 cursor-pointer text-base"
                >
                  {" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[7px]"
                  >
                    <path
                      d="M10.644 1.65602C11.1282 2.14196 11.4001 2.80001 11.4001 3.48602C11.4001 4.17203 11.1282 4.83008 10.644 5.31602L9.726 6.22802C9.054 6.90002 8.106 7.11002 7.242 6.88202L8.814 5.31602L9.27 4.85402L9.726 4.39802C10.23 3.89402 10.23 3.07802 9.726 2.57402C9.60703 2.45283 9.46511 2.35657 9.30852 2.29086C9.15193 2.22515 8.98382 2.19131 8.814 2.19131C8.64418 2.19131 8.47606 2.22515 8.31947 2.29086C8.16289 2.35657 8.02096 2.45283 7.902 2.57402L7.44 3.03002L5.412 5.05802C5.19 4.19402 5.4 3.24602 6.072 2.57402L6.984 1.65602C7.46994 1.17179 8.12799 0.899902 8.814 0.899902C9.50001 0.899902 10.1581 1.17179 10.644 1.65602ZM5.154 8.05802L8.358 4.85402C8.61 4.60202 8.61 4.19402 8.358 3.94202C8.094 3.68402 7.68 3.70802 7.44 3.94202L4.242 7.14602C3.99 7.39802 3.99 7.80602 4.242 8.05802C4.506 8.31602 4.92 8.29202 5.154 8.05802ZM4.698 9.43202L7.182 6.94202C7.41 7.80602 7.2 8.75402 6.528 9.42602L5.616 10.344C5.13006 10.8282 4.47201 11.1001 3.786 11.1001C3.09999 11.1001 2.44194 10.8282 1.956 10.344C1.47177 9.85808 1.19988 9.20003 1.19988 8.51402C1.19988 7.82801 1.47177 7.16996 1.956 6.68402L2.874 5.77202C3.546 5.10002 4.494 4.89002 5.358 5.11202L2.874 7.60202C2.364 8.10602 2.364 8.92202 2.874 9.43202C3.378 9.93602 4.194 9.93602 4.698 9.43202Z"
                      fill="white"
                    />
                  </svg>
                  Arts, Handwriting
                </Link>
                <Link
                  to={"/ulcprograms/ulc-monthly-events"}
                  className="flex gap-1 cursor-pointer text-base"
                >
                  {" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[7px]"
                  >
                    <path
                      d="M10.644 1.65602C11.1282 2.14196 11.4001 2.80001 11.4001 3.48602C11.4001 4.17203 11.1282 4.83008 10.644 5.31602L9.726 6.22802C9.054 6.90002 8.106 7.11002 7.242 6.88202L8.814 5.31602L9.27 4.85402L9.726 4.39802C10.23 3.89402 10.23 3.07802 9.726 2.57402C9.60703 2.45283 9.46511 2.35657 9.30852 2.29086C9.15193 2.22515 8.98382 2.19131 8.814 2.19131C8.64418 2.19131 8.47606 2.22515 8.31947 2.29086C8.16289 2.35657 8.02096 2.45283 7.902 2.57402L7.44 3.03002L5.412 5.05802C5.19 4.19402 5.4 3.24602 6.072 2.57402L6.984 1.65602C7.46994 1.17179 8.12799 0.899902 8.814 0.899902C9.50001 0.899902 10.1581 1.17179 10.644 1.65602ZM5.154 8.05802L8.358 4.85402C8.61 4.60202 8.61 4.19402 8.358 3.94202C8.094 3.68402 7.68 3.70802 7.44 3.94202L4.242 7.14602C3.99 7.39802 3.99 7.80602 4.242 8.05802C4.506 8.31602 4.92 8.29202 5.154 8.05802ZM4.698 9.43202L7.182 6.94202C7.41 7.80602 7.2 8.75402 6.528 9.42602L5.616 10.344C5.13006 10.8282 4.47201 11.1001 3.786 11.1001C3.09999 11.1001 2.44194 10.8282 1.956 10.344C1.47177 9.85808 1.19988 9.20003 1.19988 8.51402C1.19988 7.82801 1.47177 7.16996 1.956 6.68402L2.874 5.77202C3.546 5.10002 4.494 4.89002 5.358 5.11202L2.874 7.60202C2.364 8.10602 2.364 8.92202 2.874 9.43202C3.378 9.93602 4.194 9.93602 4.698 9.43202Z"
                      fill="white"
                    />
                  </svg>
                  ULC Monthly Events
                </Link>
                <Link
                  to={"/ulcprograms/school-college-program"}
                  className="flex gap-1 cursor-pointer text-base"
                >
                  {" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[7px]"
                  >
                    <path
                      d="M10.644 1.65602C11.1282 2.14196 11.4001 2.80001 11.4001 3.48602C11.4001 4.17203 11.1282 4.83008 10.644 5.31602L9.726 6.22802C9.054 6.90002 8.106 7.11002 7.242 6.88202L8.814 5.31602L9.27 4.85402L9.726 4.39802C10.23 3.89402 10.23 3.07802 9.726 2.57402C9.60703 2.45283 9.46511 2.35657 9.30852 2.29086C9.15193 2.22515 8.98382 2.19131 8.814 2.19131C8.64418 2.19131 8.47606 2.22515 8.31947 2.29086C8.16289 2.35657 8.02096 2.45283 7.902 2.57402L7.44 3.03002L5.412 5.05802C5.19 4.19402 5.4 3.24602 6.072 2.57402L6.984 1.65602C7.46994 1.17179 8.12799 0.899902 8.814 0.899902C9.50001 0.899902 10.1581 1.17179 10.644 1.65602ZM5.154 8.05802L8.358 4.85402C8.61 4.60202 8.61 4.19402 8.358 3.94202C8.094 3.68402 7.68 3.70802 7.44 3.94202L4.242 7.14602C3.99 7.39802 3.99 7.80602 4.242 8.05802C4.506 8.31602 4.92 8.29202 5.154 8.05802ZM4.698 9.43202L7.182 6.94202C7.41 7.80602 7.2 8.75402 6.528 9.42602L5.616 10.344C5.13006 10.8282 4.47201 11.1001 3.786 11.1001C3.09999 11.1001 2.44194 10.8282 1.956 10.344C1.47177 9.85808 1.19988 9.20003 1.19988 8.51402C1.19988 7.82801 1.47177 7.16996 1.956 6.68402L2.874 5.77202C3.546 5.10002 4.494 4.89002 5.358 5.11202L2.874 7.60202C2.364 8.10602 2.364 8.92202 2.874 9.43202C3.378 9.93602 4.194 9.93602 4.698 9.43202Z"
                      fill="white"
                    />
                  </svg>
                  School, College Program
                </Link>
                <Link
                  to={"/ulcprograms/personal-development-program"}
                  className="flex gap-1 cursor-pointer text-base"
                >
                  {" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[7px]"
                  >
                    <path
                      d="M10.644 1.65602C11.1282 2.14196 11.4001 2.80001 11.4001 3.48602C11.4001 4.17203 11.1282 4.83008 10.644 5.31602L9.726 6.22802C9.054 6.90002 8.106 7.11002 7.242 6.88202L8.814 5.31602L9.27 4.85402L9.726 4.39802C10.23 3.89402 10.23 3.07802 9.726 2.57402C9.60703 2.45283 9.46511 2.35657 9.30852 2.29086C9.15193 2.22515 8.98382 2.19131 8.814 2.19131C8.64418 2.19131 8.47606 2.22515 8.31947 2.29086C8.16289 2.35657 8.02096 2.45283 7.902 2.57402L7.44 3.03002L5.412 5.05802C5.19 4.19402 5.4 3.24602 6.072 2.57402L6.984 1.65602C7.46994 1.17179 8.12799 0.899902 8.814 0.899902C9.50001 0.899902 10.1581 1.17179 10.644 1.65602ZM5.154 8.05802L8.358 4.85402C8.61 4.60202 8.61 4.19402 8.358 3.94202C8.094 3.68402 7.68 3.70802 7.44 3.94202L4.242 7.14602C3.99 7.39802 3.99 7.80602 4.242 8.05802C4.506 8.31602 4.92 8.29202 5.154 8.05802ZM4.698 9.43202L7.182 6.94202C7.41 7.80602 7.2 8.75402 6.528 9.42602L5.616 10.344C5.13006 10.8282 4.47201 11.1001 3.786 11.1001C3.09999 11.1001 2.44194 10.8282 1.956 10.344C1.47177 9.85808 1.19988 9.20003 1.19988 8.51402C1.19988 7.82801 1.47177 7.16996 1.956 6.68402L2.874 5.77202C3.546 5.10002 4.494 4.89002 5.358 5.11202L2.874 7.60202C2.364 8.10602 2.364 8.92202 2.874 9.43202C3.378 9.93602 4.194 9.93602 4.698 9.43202Z"
                      fill="white"
                    />
                  </svg>
                  Personal Development Program
                </Link>
                <Link
                  to={"/ulcprograms/creative-competition-seminars"}
                  className="flex gap-1 cursor-pointer text-base"
                >
                  {" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[7px]"
                  >
                    <path
                      d="M10.644 1.65602C11.1282 2.14196 11.4001 2.80001 11.4001 3.48602C11.4001 4.17203 11.1282 4.83008 10.644 5.31602L9.726 6.22802C9.054 6.90002 8.106 7.11002 7.242 6.88202L8.814 5.31602L9.27 4.85402L9.726 4.39802C10.23 3.89402 10.23 3.07802 9.726 2.57402C9.60703 2.45283 9.46511 2.35657 9.30852 2.29086C9.15193 2.22515 8.98382 2.19131 8.814 2.19131C8.64418 2.19131 8.47606 2.22515 8.31947 2.29086C8.16289 2.35657 8.02096 2.45283 7.902 2.57402L7.44 3.03002L5.412 5.05802C5.19 4.19402 5.4 3.24602 6.072 2.57402L6.984 1.65602C7.46994 1.17179 8.12799 0.899902 8.814 0.899902C9.50001 0.899902 10.1581 1.17179 10.644 1.65602ZM5.154 8.05802L8.358 4.85402C8.61 4.60202 8.61 4.19402 8.358 3.94202C8.094 3.68402 7.68 3.70802 7.44 3.94202L4.242 7.14602C3.99 7.39802 3.99 7.80602 4.242 8.05802C4.506 8.31602 4.92 8.29202 5.154 8.05802ZM4.698 9.43202L7.182 6.94202C7.41 7.80602 7.2 8.75402 6.528 9.42602L5.616 10.344C5.13006 10.8282 4.47201 11.1001 3.786 11.1001C3.09999 11.1001 2.44194 10.8282 1.956 10.344C1.47177 9.85808 1.19988 9.20003 1.19988 8.51402C1.19988 7.82801 1.47177 7.16996 1.956 6.68402L2.874 5.77202C3.546 5.10002 4.494 4.89002 5.358 5.11202L2.874 7.60202C2.364 8.10602 2.364 8.92202 2.874 9.43202C3.378 9.93602 4.194 9.93602 4.698 9.43202Z"
                      fill="white"
                    />
                  </svg>
                  Creative, Competition Seminars
                </Link>
                <Link
                  to={"/ulcprograms/presentation-personal-development"}
                  className="flex gap-1 cursor-pointer text-base"
                >
                  {" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[7px]"
                  >
                    <path
                      d="M10.644 1.65602C11.1282 2.14196 11.4001 2.80001 11.4001 3.48602C11.4001 4.17203 11.1282 4.83008 10.644 5.31602L9.726 6.22802C9.054 6.90002 8.106 7.11002 7.242 6.88202L8.814 5.31602L9.27 4.85402L9.726 4.39802C10.23 3.89402 10.23 3.07802 9.726 2.57402C9.60703 2.45283 9.46511 2.35657 9.30852 2.29086C9.15193 2.22515 8.98382 2.19131 8.814 2.19131C8.64418 2.19131 8.47606 2.22515 8.31947 2.29086C8.16289 2.35657 8.02096 2.45283 7.902 2.57402L7.44 3.03002L5.412 5.05802C5.19 4.19402 5.4 3.24602 6.072 2.57402L6.984 1.65602C7.46994 1.17179 8.12799 0.899902 8.814 0.899902C9.50001 0.899902 10.1581 1.17179 10.644 1.65602ZM5.154 8.05802L8.358 4.85402C8.61 4.60202 8.61 4.19402 8.358 3.94202C8.094 3.68402 7.68 3.70802 7.44 3.94202L4.242 7.14602C3.99 7.39802 3.99 7.80602 4.242 8.05802C4.506 8.31602 4.92 8.29202 5.154 8.05802ZM4.698 9.43202L7.182 6.94202C7.41 7.80602 7.2 8.75402 6.528 9.42602L5.616 10.344C5.13006 10.8282 4.47201 11.1001 3.786 11.1001C3.09999 11.1001 2.44194 10.8282 1.956 10.344C1.47177 9.85808 1.19988 9.20003 1.19988 8.51402C1.19988 7.82801 1.47177 7.16996 1.956 6.68402L2.874 5.77202C3.546 5.10002 4.494 4.89002 5.358 5.11202L2.874 7.60202C2.364 8.10602 2.364 8.92202 2.874 9.43202C3.378 9.93602 4.194 9.93602 4.698 9.43202Z"
                      fill="white"
                    />
                  </svg>
                  Presentation & Personal Development
                </Link>
              </div>
            </div>
            <div className=" flex justify-center items-center flex-col py-2">
              {/* <div className="min-w-[300px] text-center font-semibold tracking-wide"> */}
              <div className="col-md-4 col-lg-3 mb-2 md:mb-4 flex md:items-start md:gap-3 text-sm md:text-base flex-col items-center gap-1">
                <h2 className="fw-bold text-xl mb-1"> Contacts</h2>
                <p className="flex gap-1 cursor-pointer text-base items-center">
                  {" "}
                  <svg
                    width="9"
                    height="15"
                    viewBox="0 0 9 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[1px]"
                  >
                    <path
                      d="M3.5625 12.5H5.4375C5.52083 12.5 5.59375 12.4688 5.65625 12.4063C5.71875 12.3438 5.75 12.2708 5.75 12.1875C5.75 12.1042 5.71875 12.0313 5.65625 11.9688C5.59375 11.9063 5.52083 11.875 5.4375 11.875H3.5625C3.47917 11.875 3.40625 11.9063 3.34375 11.9688C3.28125 12.0313 3.25 12.1042 3.25 12.1875C3.25 12.2708 3.28125 12.3438 3.34375 12.4063C3.40625 12.4688 3.47917 12.5 3.5625 12.5ZM1.375 14.375C1.03125 14.375 0.736876 14.2525 0.491876 14.0075C0.246876 13.7625 0.124584 13.4683 0.125001 13.125V1.875C0.125001 1.53125 0.247501 1.23688 0.492501 0.991876C0.737501 0.746876 1.03167 0.624584 1.375 0.625001H7.625C7.96875 0.625001 8.26313 0.747501 8.50813 0.992501C8.75313 1.2375 8.87542 1.53167 8.875 1.875V13.125C8.875 13.4688 8.7525 13.7631 8.5075 14.0081C8.2625 14.2531 7.96833 14.3754 7.625 14.375H1.375ZM1.375 10H7.625V3.75H1.375V10Z"
                      fill="white"
                    />
                  </svg>
                  +977 985-1169602
                </p>
                <p className="flex gap-1 cursor-pointer text-base items-center">
                  {" "}
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[3px]"
                  >
                    <path
                      d="M0.875 12.125V3.375H4.625V2.125L6.5 0.25L8.375 2.125V5.875H12.125V12.125H0.875ZM2.125 10.875H3.375V9.625H2.125V10.875ZM2.125 8.375H3.375V7.125H2.125V8.375ZM2.125 5.875H3.375V4.625H2.125V5.875ZM5.875 10.875H7.125V9.625H5.875V10.875ZM5.875 8.375H7.125V7.125H5.875V8.375ZM5.875 5.875H7.125V4.625H5.875V5.875ZM5.875 3.375H7.125V2.125H5.875V3.375ZM9.625 10.875H10.875V9.625H9.625V10.875ZM9.625 8.375H10.875V7.125H9.625V8.375Z"
                      fill="white"
                    />
                  </svg>
                  <Link
                    to="https://www.google.com/maps/dir//Utsah+Learning+Center+(ULC)+P75M%2B4V4+kathmandu+44660/@27.7077544,85.2846905,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39eb19d5315c00ff:0xd473080d79394f0b!2m2!1d85.2846905!2d27.7077544"
                    target="_blank"
                  >
                    Bahiti Marg, Sitapaila, Nepal
                  </Link>
                </p>
                <p className="flex gap-1 cursor-pointer text-base items-center">
                  {" "}
                  <svg
                    width="13"
                    height="11"
                    viewBox="0 0 13 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[4px]"
                  >
                    <path
                      d="M11.5 0.5H1.5C0.8125 0.5 0.25625 1.0625 0.25625 1.75L0.25 9.25C0.25 9.9375 0.8125 10.5 1.5 10.5H11.5C12.1875 10.5 12.75 9.9375 12.75 9.25V1.75C12.75 1.0625 12.1875 0.5 11.5 0.5ZM11.5 3L6.5 6.125L1.5 3V1.75L6.5 4.875L11.5 1.75V3Z"
                      fill="white"
                    />
                  </svg>
                  <Link
                    to="https://www.google.com/intl/ne/gmail/about/#inbox/FMfcgxwGDNKsjSwJkvMTMTLKrjVksQzn?compose=DmwnWrRnZVqNlPCfkmjnbzXRjjQKVLwSjQGbztgrJNsrvqRrsXxgtSJcGDRcLhhDKtbbVjKlxXDQ"
                    target="_blank"
                  >
                    {" "}
                    info@utsahlearningcenter.com
                  </Link>
                </p>
                <p className="flex gap-1 cursor-pointer text-base items-center">
                  {" "}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[3px]"
                  >
                    <path
                      d="M1.21624 4.82184C0.88999 4.71309 0.886865 4.53746 1.22249 4.42559L13.1519 0.449339C13.4825 0.339339 13.6719 0.524339 13.5794 0.848089L10.1706 12.7768C10.0769 13.1075 9.88624 13.1187 9.74624 12.805L7.49999 7.74996L11.25 2.74996L6.24999 6.49996L1.21624 4.82184Z"
                      fill="white"
                    />
                  </svg>
                  <Link
                    to="https://www.google.com/maps/dir//Utsah+Learning+Center+(ULC)+P75M%2B4V4+kathmandu+44660/@27.7077544,85.2846905,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39eb19d5315c00ff:0xd473080d79394f0b!2m2!1d85.2846905!2d27.7077544"
                    target="_blank"
                  >
                    {" "}
                    Google Map
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* <div className="bg-gradient-to-r from-[#0000FFCF] to-[#0000CBFC] text-white w-full text-center p-4"> */}
        <div className=" text-white w-full text-center p-4 text-base">
          <p>All Copyright © 2019-2023. All Rights Reserved.</p>
          <p>
            Powered by
            <Link to="https://iotech.com.np/">
              {" "}
              {""}
              I.O Tech Nepal
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
