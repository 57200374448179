import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Album from "./components/Pages/Album/Album";
import Footer from "./components/Common/Footer";
import Navigation from "./components/Common/Navigation";
import Home from "./components/Pages/Home/Home";
import About from "./components/Pages/About/About";
import AllGallery from "./components/Pages/Album/AllGallery";
import EachImg from "./components/Pages/Album/EachImg";
import Contact from "./components/Pages/contact/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./components/Pages/PageNotFound";
import Programcatd from "./components/Pages/UlcProgram/Programcatd";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Navigate to="/" />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/event-gallery" element={<Album />} />
          <Route path="/event-gallery/album/:id" element={<AllGallery />} />
          <Route path="/ulcprograms/:content" element={<Programcatd />} />
          <Route
            path="/event-gallery/album/:id/image/:imgId"
            element={<EachImg />}
          />
          <Route path="/gallery" element={<Album />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <ToastContainer position="top-right" autoClose={2000} theme="colored" />
    </>
  );
}

export default App;
