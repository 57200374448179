import React from "react";

const About = () => {
  return (
    <div className="w-full bg-[#E0E0E0] flex justify-center items-center my-10">
      <div className="lg:w-[45%] max-w-fit max-h-full w-[95%] lg:max-h-[90vh] bg-[#FFFFFF40] m-auto rounded-[31px] shadow-lg">
        <div className="lg:p-10 px-4 py-8">
          <div className="heading flex mb-5 justify-center items-center flex-col">
            <h2 className="text-center font-semibold md:text-2xl text-xl tracking-wide text-[#444444]">
              ABOUT US
            </h2>
            <div className="bg-gradient-to-r from-slate-200 via-black to-slate-200 h-[2px] w-[120px]"></div>
          </div>
          <p className="text-justify text-sm md:text-base lg:px-3 px-1 tracking-wide">
            Utsah Learning Center, established in 2015 in kathmandu, is a
            research and consulting company that work in collaboration with
            various organizations and educational institution. We work in
            research projects, organize competitions, trainings, conferences and
            workshops. Utsah Learning Center, established in 2015 in kathmandu,
            is a research and consulting company that work in collaboration with
            various organizations and educational institution. We work in
            research projects, organize competitions, trainings, conferences and
            workshops.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
