import React from "react";

const ProgramCards = (props) => {
  const { data } = props;
  return (
    <div className="min-h-[50vh] h-full py-8 rounded-md shadow-md max-w-[1000px] md:w-[75%] w-[95%] m-auto mt-10 lg:mt-20 flex flex-col justify-center items-center bg-[#ffffff40]">
      <div className="heading flex mb-6 justify-center items-center flex-col">
        <h2 className="text-center font-semibold px-3 text-xl lg:text-2xl tracking-wide text-[#444444] mb-2">
          {data.title}
        </h2>
        <div className="bg-gradient-to-r from-slate-200 via-black to-slate-200 h-[2px] w-full"></div>
      </div>
      <div className="flex h-full justify-center items-center px-5 md:px-10 flex-col lg:flex-row gap-4 lg:gap-10">
        <div className="lg:w-[45%] w-full  flex justify-center items-center mt-3">
          <img src={data.img} alt="" className="min-h-[180px] max-h-[220px] object-contain m-auto" />
        </div>
        
        <div className="w-full lg:w-[55%] text-justify mt-2">{data.dis}</div>
      </div>
    </div>
  );
};

export default ProgramCards;
