// import Swiper core and required modules
import { Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import img1 from "../../../img/background1.jpg";
import img2 from "../../../img/background2.jpg";
import pdf from "../../../img/pdf/apply.pdf";
import { Link } from "react-router-dom";

const Carousel = () => {
  
  return (
    <div>
      <Swiper
        // install Swiper modules
        modules={[Pagination, A11y, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 30000,
          disableOnInteraction: false,
        }}
      >
        <div className="w-full h-[93vh] bg-black" key={0}>
          <SwiperSlide key={img1}>
            <div className="w-full h-[89vh] bg-black relative flex justify-center items-center">
              <img
                src={img1}
                alt=""
                className="w-full h-full opacity-30 object-cover"
              />
              <div className="absolute !text-slate-200 text-center flex gap-3 flex-col">
                <h2 className="md:text-[40px] tracking-wider font-semibold text-[20px]">
                  Want to be connected with
                </h2>
                <h1 className="md:text-3xl text-xl uppercase tracking-wider font-semibold">
                  "Utsha learning center"
                </h1>
                <div className="but flex md:gap-3 gap-2 justify-center items-center mt-2">
                  <Link
                    to={pdf}
                    className="md:px-4 px-3 rounded-md tracking-wide md:text-base text-sm py-[6px] my-3 hover:text-white from-[#0000A5] to-[#00005A] hover:from-[#00005A] hover:to-[#0000A5] bg-gradient-to-r"
                    target={"blank"}
                  >
                    How To Apply
                  </Link>
                  <button className="md:px-4 px-3 rounded-md tracking-wide md:text-base text-sm py-[6px] my-3 hover:text-white from-[#0000A5] to-[#00005A] hover:from-[#00005A] hover:to-[#0000A5] bg-gradient-to-r">
                    ULC Competition Form
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </div>
        <div className="w-full h-[93vh] bg-black" key={1}>
          <SwiperSlide>
            <div className="w-full h-[89vh] bg-black relative flex justify-center items-center">
              <img
                src={img2}
                alt=""
                className="w-full h-full opacity-30 object-cover"
              />
              <div className="absolute !text-slate-200 text-center flex gap-3 flex-col">
                <h2 className="md:text-[40px] text-[20px] tracking-wider font-semibold">
                  Want to be connected with
                </h2>
                <h1 className="md:text-3xl text-xl uppercase tracking-wider font-semibold">
                  "Utsha learning center"
                </h1>
                <div className="but flex md:gap-3 gap-2 justify-center items-center mt-2">
                  <Link
                    target={"blank"}
                    to={pdf}
                    className="md:px-4 px-3 rounded-md tracking-wide md:text-base text-sm py-[6px] my-3 hover:text-white from-[#0000A5] to-[#00005A] hover:from-[#00005A] hover:to-[#0000A5] bg-gradient-to-r"
                  >
                    How To Apply
                  </Link>
                  <button className="md:px-4 px-3 rounded-md tracking-wide md:text-base text-sm py-[6px] my-3 hover:text-white from-[#0000A5] to-[#00005A] hover:from-[#00005A] hover:to-[#0000A5] bg-gradient-to-r">
                    ULC Competition Form
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper>
    </div>
  );
};

export default Carousel;
