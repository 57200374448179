import React from "react";
import AlbumCards from "./AlbumCards";
import { data } from "../data/Album";

const AllAlbum = () => {

  return (
    <div className="max-w-[90%] mx-auto py-8 px-0 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((e) => {
          return (
            <div key={e.id}>
              <AlbumCards data={e} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllAlbum;
