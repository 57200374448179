import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ImageSwiper from "./ImageSwiper";
import { data } from "../data/Album";

const EachImg = () => {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  const { id } = useParams();
  const fData = data.filter((e) => e.id === Number(id));

  return (
    <div className="bg-[#08080866] lg:p-10 p-5 px-0 w-full lg:min-h-[90vh]">
      <div className="bg-[#262626] rounded-lg shadow-lg w-[90%] h-full lg:w-[70%] lg:h-[95vh] m-auto my-4 overflow-hidden py-16 relative flex items-center">
        <Link
          to={`/event-gallery/album/${id}`}
          className="absolute top-2 right-2 cursor-pointer z-50"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12C2 6.47 6.47 2 12 2ZM15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"
              fill="white"
            />
          </svg>
        </Link>
        <ImageSwiper data={fData[0] && fData[0].gallery} />
      </div>
    </div>
  );
};

export default EachImg;
