import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="min-h-[75vh] min-w-full flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-5">
        <h3 className="text-4xl text-blue-900 tracking-wide">404| Page Not Found.</h3>
        <p className="text-base text-blue-900">We looked everywhere for this page.</p>
        <Link
          to={"/"}
          className="border border-blue-900 px-5 py-1 shadow rounded-sm tracking-wider hover:bg-blue-900 hover:text-white duration-300"
        >
          {" "}
          Return Home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
