import React, { useState } from "react";
import finalogo from "../../img/ULC logo.png";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { FaTiktok } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { BsMessenger } from "react-icons/bs";
import Uppnavv from "./Uppnavv";

const Navigation = () => {
  const location = useLocation().pathname;

  const [hamClk, setHamClk] = useState(false);

  if (hamClk) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  return (
    <div className="relative z-[10000000000000]">
      <div className={`absolute right-0 -top-[56%] w-[85%] hidden lg:block`}>
        <Uppnavv />
      </div>
      <div className="hidden lg:block">
        <div className="relative w-full h-[7vh] mt-10 bg-gradient-to-r from-[#0000A5] to-[#00005A] flex justify-between z-50">
          <Link to={"/"} className="ml-[5%] -mt-9 w-[15%] z-40">
            <img src={finalogo} alt="" className="h-[125px] w-[215px] " />
          </Link>
          <div className="w-[50%]">
            <ul className="flex justify-end items-center h-full gap-9 text-[16px]">
              <li className="relative flex justify-center py-3 items-end navCss">
                {" "}
                <Link to="/" className="hover:text-slate-50 text-[#E0E0E0]">
                  {" "}
                  HOME{" "}
                </Link>
                <div
                  className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                    location === "/" && "!block"
                  }`}
                ></div>
              </li>
              <li className="relative flex justify-center py-3 items-end navCss">
                {" "}
                <Link
                  to="/aboutus"
                  className="hover:text-slate-50 text-[#E0E0E0]"
                >
                  {" "}
                  ABOUT US{" "}
                </Link>
                <div
                  className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                    location === "/aboutus" && "!block"
                  }`}
                ></div>
              </li>
              <li className="relative flex justify-center py-3 items-end navCss categoryNav">
                {" "}
                <Link className="hover:text-slate-50 text-[#E0E0E0]">
                  {" "}
                  ULC PROGRAMS{" "}
                </Link>
                <div
                  className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                    location.substring(0, 12) === "/ulcprograms" && "!block"
                  }`}
                ></div>
                <div className="subCat absolute top-12 min-w-[300px] left-0 bg-gradient-to-r from-[#2828dd] to-[#0000A5] z-40">
                  <ul>
                    <li className=" border-b w-full border-slate-500">
                      <Link
                        to="/ulcprograms/arts-handwriting"
                        className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                          location === "/ulcprograms/arts-handwriting" &&
                          "bg-[#00005A]"
                        } w-full text-white`}
                      >
                        {" "}
                        Arts & Handwriting
                      </Link>
                    </li>
                    <li className=" border-b w-full border-slate-500">
                      <Link
                        to="/ulcprograms/ulc-monthly-events"
                        className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                          location === "/ulcprograms/ulc-monthly-events" &&
                          "bg-[#00005A]"
                        } w-full text-white`}
                      >
                        {" "}
                        ULC Monthly Events
                      </Link>
                    </li>
                    <li className=" border-b w-full border-slate-500">
                      <Link
                        to="/ulcprograms/school-college-program"
                        className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                          location === "/ulcprograms/school-college-program" &&
                          "bg-[#00005A]"
                        } w-full text-white`}
                      >
                        {" "}
                        School & College Program
                      </Link>
                    </li>
                    <li className=" border-b w-full border-slate-500">
                      <Link
                        to="/ulcprograms/personal-development-program"
                        className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                          location ===
                            "/ulcprograms/personal-development-program" &&
                          "bg-[#00005A]"
                        } w-full text-white`}
                      >
                        {" "}
                        Personal Development Program
                      </Link>
                    </li>
                    <li className=" border-b w-full border-slate-500">
                      <Link
                        to="/ulcprograms/creative-competition-seminars"
                        className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                          location ===
                            "/ulcprograms/creative-competition-seminars" &&
                          "bg-[#00005A]"
                        } w-full text-white`}
                      >
                        {" "}
                        Creative, Competition Seminars
                      </Link>
                    </li>
                    <li className=" border-b w-full border-slate-500">
                      <Link
                        to="/ulcprograms/presentation-personal-development"
                        className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                          location ===
                            "/ulcprograms/presentation-personal-development" &&
                          "bg-[#00005A]"
                        } w-full text-white`}
                      >
                        {" "}
                        Presentation & Personal Development
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="relative flex justify-center py-3 items-end navCss">
                {" "}
                <Link
                  to="/event-gallery"
                  className="hover:text-slate-50 text-[#E0E0E0]"
                >
                  {" "}
                  EVENT GALLERY{" "}
                </Link>
                <div
                  className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                    location.substring(0, 14) === "/event-gallery" && "!block"
                  }`}
                ></div>
              </li>
              <li className="relative flex justify-center py-3 items-end navCss">
                {" "}
                <Link
                  to="/contact"
                  className="hover:text-slate-50 text-[#E0E0E0]"
                >
                  {" "}
                  CONTACT{" "}
                </Link>
                <div
                  className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                    location === "/contact" && "!block"
                  }`}
                ></div>
              </li>
            </ul>
          </div>

          <div className="flex items-center w-[20%] gap-3 border-l border-white pl-4 ">
            <p className="text-[#E0E0E0] mb-1 text-sm"> Follows us on:</p>
            <Link
              to="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJfqchXCZmMnWSncZhDDGfkbHDgXrxRlRcHbdVmhNRcNtLffbWggXPLMfspQtKZFLtJMVqB"
              target="_blank"
            >
              <MdEmail className="text-[#E0E0E0] scale-110 hover:text-slate-400" />
            </Link>
            <Link
              to="https://www.facebook.com/people/UTSAHLearningCenter/100057525036786/"
              target="_blank"
            >
              {" "}
              <FaFacebook className="text-[#E0E0E0] hover:text-slate-400" />
            </Link>

            <Link
              to="https://instagram.com/utsahlearning?igshid=YmMyMTA2M2Y="
              target="blank"
            >
              <AiFillInstagram className="text-[#E0E0E0] scale-125 hover:text-slate-400" />
            </Link>
            <Link to="/">
              <FaTiktok className="text-[#E0E0E0] hover:text-slate-400" />
            </Link>
            <Link to="/">
              <BsMessenger className="text-[#E0E0E0] hover:text-slate-400" />
            </Link>
          </div>
        </div>
      </div>
      <div className="h-[7vh] lg:hidden block">
        <div className="lg:hidden block fixed top-0 w-screen z-50">
          <div className="w-screen !top-0 h-[7vh] bg-gradient-to-r from-[#0000A5] to-[#00005A] duration-300 flex justify-between z-50 items-center">
            <FaBars
              className={`absolute right-10 text-white text-xl cursor-pointer select-none z-50 ${
                hamClk ? "hidden" : "block"
              }`}
              onClick={() => setHamClk((p) => !p)}
            />
            <RxCross2
              className={`absolute right-5 text-white text-3xl cursor-pointer select-none z-50 ${
                !hamClk ? "hidden" : "block"
              }`}
              onClick={() => setHamClk((p) => !p)}
            />
            <div id="logo" className="absolute left-3 ">
              <img src={finalogo} alt="" className="h-[7vh] w-full z-[100]" />
            </div>
            <div
              className={`h-screen w-full top-0 bottom-0 bg-gradient-to-r from-[#0000A5] to-[#00005A] ${
                hamClk ? "left-0" : "-left-full"
              } absolute `}
            >
              <div id="logo" className="absolute left-3 top-3">
                <img src={finalogo} alt="" className="h-[9vh] w-full z-[100]" />
              </div>
              {/* <div className="w-[50%]"> */}
              <ul className="flex justify-center flex-col items-start h-full gap-1 ml-10 text-[16px]">
                <li className="relative flex justify-center py-3 items-end navCss">
                  {" "}
                  <Link
                    to="/"
                    className="hover:text-slate-50 text-[#E0E0E0]"
                    onClick={() => setHamClk(false)}
                  >
                    {" "}
                    HOME{" "}
                  </Link>
                  <div
                    className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                      location === "/" && "!block"
                    }`}
                  ></div>
                </li>
                <li className="relative flex justify-center py-3 items-end navCss">
                  {" "}
                  <Link
                    onClick={() => setHamClk(false)}
                    to="/aboutus"
                    className="hover:text-slate-50 text-[#E0E0E0]"
                  >
                    {" "}
                    ABOUT US{" "}
                  </Link>
                  <div
                    className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                      location === "/aboutus" && "!block"
                    }`}
                  ></div>
                </li>
                <li className="relative flex justify-center py-3 items-end navCss categoryNav">
                  {" "}
                  <Link className="hover:text-slate-50 text-[#E0E0E0]">
                    {" "}
                    ULC PROGRAMS{" "}
                  </Link>
                  <div
                    className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                      location.substring(0, 12) === "/ulcprograms" && "!block"
                    }`}
                  ></div>
                  <div className="subCat absolute top-12 min-w-[300px] left-0 bg-gradient-to-r from-[#2828dd] to-[#0000A5] z-40">
                    <ul>
                      <li className=" border-b w-full border-slate-500">
                        <Link
                          onClick={() => setHamClk(false)}
                          to="/ulcprograms/arts-handwriting"
                          className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                            location === "/ulcprograms/arts-handwriting" &&
                            "bg-[#00005A]"
                          } w-full text-white`}
                        >
                          {" "}
                          Arts & Handwriting
                        </Link>
                      </li>
                      <li className=" border-b w-full border-slate-500">
                        <Link
                          onClick={() => setHamClk(false)}
                          to="/ulcprograms/ulc-monthly-events"
                          className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                            location === "/ulcprograms/ulc-monthly-events" &&
                            "bg-[#00005A]"
                          } w-full text-white`}
                        >
                          {" "}
                          ULC Monthly Events
                        </Link>
                      </li>
                      <li className=" border-b w-full border-slate-500">
                        <Link
                          onClick={() => setHamClk(false)}
                          to="/ulcprograms/school-college-program"
                          className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                            location ===
                              "/ulcprograms/school-college-program" &&
                            "bg-[#00005A]"
                          } w-full text-white`}
                        >
                          {" "}
                          School & College Program
                        </Link>
                      </li>
                      <li className=" border-b w-full border-slate-500">
                        <Link
                          onClick={() => setHamClk(false)}
                          to="/ulcprograms/personal-development-program"
                          className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                            location ===
                              "/ulcprograms/personal-development-program" &&
                            "bg-[#00005A]"
                          } w-full text-white`}
                        >
                          {" "}
                          Personal Development Program
                        </Link>
                      </li>
                      <li className=" border-b w-full border-slate-500">
                        <Link
                          onClick={() => setHamClk(false)}
                          to="/ulcprograms/creative-competition-seminars"
                          className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                            location ===
                              "/ulcprograms/creative-competition-seminars" &&
                            "bg-[#00005A]"
                          } w-full text-white`}
                        >
                          {" "}
                          Creative, Competition Seminars
                        </Link>
                      </li>
                      <li className=" border-b w-full border-slate-500">
                        <Link
                          onClick={() => setHamClk(false)}
                          to="/ulcprograms/presentation-personal-development"
                          className={`px-3 py-[5px] hover:bg-[#00005A] block ${
                            location ===
                              "/ulcprograms/presentation-personal-development" &&
                            "bg-[#00005A]"
                          } w-full text-white`}
                        >
                          {" "}
                          Presentation & Personal Development
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="relative flex justify-center py-3 items-end navCss">
                  {" "}
                  <Link
                    onClick={() => setHamClk(false)}
                    to="/event-gallery"
                    className="hover:text-slate-50 text-[#E0E0E0]"
                  >
                    {" "}
                    EVENT GALLERY{" "}
                  </Link>
                  <div
                    className={`h-[2px] bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                      location.substring(0, 14) === "/event-gallery" && "!block"
                    }`}
                  ></div>
                </li>
                <li className="relative flex justify-center py-3 items-end navCss">
                  {" "}
                  <Link
                    onClick={() => setHamClk(false)}
                    to="/contact"
                    className="hover:text-slate-50 text-[#E0E0E0]"
                  >
                    {" "}
                    CONTACT{" "}
                  </Link>
                  <div
                    className={`h-[2px] !overflow-hidden bottom-[9px] absolute w-[110%] navCssCh rounded-md bg-gradient-to-r from-pink-500 via-[#E9A31D] to-[#3F2B04] ${
                      location === "/contact" && "!block"
                    }`}
                  ></div>
                </li>
              </ul>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
