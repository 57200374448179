//for school
import img1 from "../../../img/School/01.jpg";
import img2 from "../../../img/School/02.jpg";
import img3 from "../../../img/School/03.jpg";
import img4 from "../../../img/School/04.jpg";
import img5 from "../../../img/School/05.jpg";
import img6 from "../../../img/School/06.jpg";
import img7 from "../../../img/School/07.JPG";
import img8 from "../../../img/School/08.JPG";
import img9 from "../../../img/School/09.JPG";
import img10 from "../../../img/School/10.JPG";

// for college img
import cImg1 from "../../../img/college/01.jpg";
import cImg2 from "../../../img/college/02.jpg";
import cImg3 from "../../../img/college/03.jpg";
import cImg4 from "../../../img/college/04.jpg";
import cImg5 from "../../../img/college/05.jpg";
import cImg6 from "../../../img/college/06.JPG";
import cImg7 from "../../../img/college/07.jpg";
import cImg8 from "../../../img/college/08.JPG";
import cImg9 from "../../../img/college/09.JPG";
import cImg10 from "../../../img/college/10.JPG";
import cImg11 from "../../../img/college/11.JPG";
import cImg12 from "../../../img/college/12.JPG";
import cImg13 from "../../../img/college/13.JPG";
import cImg14 from "../../../img/college/14.JPG";
import cImg15 from "../../../img/college/15.jpg";
import cImg16 from "../../../img/college/16.jpg";
import cImg17 from "../../../img/college/17.jpg";
import cImg18 from "../../../img/college/18.jpg";

//for others
import cimg1 from "../../../img/others/15.JPG";
import cimg2 from "../../../img/others/16.JPG";
import cimg3 from "../../../img/others/17.JPG";
import cimg4 from "../../../img/others/18.JPG";
import cimg5 from "../../../img/others/19.jpg";
import cimg6 from "../../../img/others/20.jpg";
import cimg7 from "../../../img/others/21.jpg";
import cimg8 from "../../../img/others/22.JPG";
import cimg9 from "../../../img/others/23.jpg";
import cimg10 from "../../../img/others/24.JPG";
import cimg11 from "../../../img/others/25.jpg";
import cimg12 from "../../../img/others/26.jpg";
import cimg13 from "../../../img/others/27.jpg";
import cimg14 from "../../../img/others/28.jpg";
import cimg15 from "../../../img/others/29.jpg";
import cimg16 from "../../../img/others/30.jpg";
import cimg17 from "../../../img/others/31.jpg";
import cimg18 from "../../../img/others/32.jpg";
import cimg19 from "../../../img/others/33.jpg";
import cimg20 from "../../../img/others/34.jpg";
import cimg21 from "../../../img/others/35.JPG";
import cimg22 from "../../../img/others/36.jpg";
import cimg23 from "../../../img/others/37.jpg";
import cimg24 from "../../../img/others/38.JPG";
import cimg25 from "../../../img/others/39.JPG";
import cimg26 from "../../../img/others/40.JPG";
import cimg27 from "../../../img/others/41.JPG";
import cimg28 from "../../../img/others/42.JPG";
import cimg29 from "../../../img/others/43.JPG";
import cimg30 from "../../../img/others/44.JPG";
import cimg31 from "../../../img/others/45.jpg";
import cimg32 from "../../../img/others/46.JPG";

export const data = [
  {
    id: 1,
    image: img1,
    title: "Personal Development Program",
    gallery: [
      {
        id: 1,
        galleryImg: img1,
      },
      {
        id: 2,
        galleryImg: img2,
      },
      {
        id: 3,
        galleryImg: img3,
      },
      {
        id: 4,
        galleryImg: img4,
      },
      {
        id: 5,
        galleryImg: img5,
      },
      {
        id: 6,
        galleryImg: img6,
      },
      {
        id: 7,
        galleryImg: img7,
      },
      {
        id: 8,
        galleryImg: img8,
      },
      {
        id: 9,
        galleryImg: img9,
      },
      {
        id: 10,
        galleryImg: img10,
      },
    ],
    date: "2079-2-2",
  },
  {
    id: 2,
    image: cimg1,
    title: "Personal Development Program",
    gallery: [
      {
        id: 1,
        galleryImg: cImg1,
      },
      {
        id: 2,
        galleryImg: cImg2,
      },
      {
        id: 3,
        galleryImg: cImg3,
      },
      {
        id: 4,
        galleryImg: cImg4,
      },
      {
        id: 5,
        galleryImg: cImg5,
      },
      {
        id: 6,
        galleryImg: cImg6,
      },
      {
        id: 7,
        galleryImg: cImg7,
      },
      {
        id: 8,
        galleryImg: cImg8,
      },
      {
        id: 9,
        galleryImg: cImg9,
      },
      {
        id: 10,
        galleryImg: cImg10,
      },
      {
        id: 11,
        galleryImg: cImg11,
      },
      {
        id: 12,
        galleryImg: cImg12,
      },
      {
        id: 13,
        galleryImg: cImg13,
      },
      {
        id: 14,
        galleryImg: cImg14,
      },
      {
        id: 15,
        galleryImg: cImg15,
      },
      {
        id: 16,
        galleryImg: cImg16,
      },
      {
        id: 17,
        galleryImg: cImg17,
      },
      {
        id: 18,
        galleryImg: cImg18,
      },
    ],
  },
  {
    id: 3,
    image: cImg1,
    title: "Personal Development Program",
    gallery: [
      {
        id: 1,
        galleryImg: cimg1,
      },
      {
        id: 2,
        galleryImg: cimg2,
      },
      {
        id: 3,
        galleryImg: cimg3,
      },
      {
        id: 4,
        galleryImg: cimg4,
      },
      {
        id: 5,
        galleryImg: cimg5,
      },
      {
        id: 6,
        galleryImg: cimg6,
      },
      {
        id: 7,
        galleryImg: cimg7,
      },
      {
        id: 8,
        galleryImg: cimg8,
      },
      {
        id: 9,
        galleryImg: cimg9,
      },
      {
        id: 10,
        galleryImg: cimg10,
      },
      {
        id: 11,
        galleryImg: cimg11,
      },
      {
        id: 12,
        galleryImg: cimg12,
      },
      {
        id: 13,
        galleryImg: cimg13,
      },
      {
        id: 14,
        galleryImg: cimg14,
      },
      {
        id: 15,
        galleryImg: cimg15,
      },
      {
        id: 16,
        galleryImg: cimg16,
      },
      {
        id: 17,
        galleryImg: cimg17,
      },
      {
        id: 18,
        galleryImg: cimg18,
      },
      {
        id: 19,
        galleryImg: cimg19,
      },
      {
        id: 20,
        galleryImg: cimg20,
      },
      {
        id: 21,
        galleryImg: cimg21,
      },
      {
        id: 22,
        galleryImg: cimg22,
      },
      {
        id: 23,
        galleryImg: cimg23,
      },
      {
        id: 24,
        galleryImg: cimg24,
      },
      {
        id: 25,
        galleryImg: cimg25,
      },
      {
        id: 26,
        galleryImg: cimg26,
      },
      {
        id: 27,
        galleryImg: cimg27,
      },
      {
        id: 28,
        galleryImg: cimg28,
      },
      {
        id: 29,
        galleryImg: cimg29,
      },
      {
        id: 30,
        galleryImg: cimg30,
      },
      {
        id: 31,
        galleryImg: cimg31,
      },
      {
        id: 32,
        galleryImg: cimg32,
      },
    ],
    date: "2079-2-2",
  },
];
