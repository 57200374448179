import React from "react";
import { Link } from "react-router-dom";

const Uppnavv = () => {
  return (
    <>
      <div className="w-full bg-[#cdcdcd] py-[2px] flex flex-row-reverse gap-5 px-10 shadow">
        <div className="phone flex items-center gap-1 border-l pl-5 border-slate-700">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.93758 14.1667H10.0626C10.157 14.1667 10.2397 14.1313 10.3105 14.0604C10.3813 13.9896 10.4167 13.9069 10.4167 13.8125C10.4167 13.7181 10.3813 13.6354 10.3105 13.5646C10.2397 13.4937 10.157 13.4583 10.0626 13.4583H7.93758C7.84314 13.4583 7.7605 13.4937 7.68967 13.5646C7.61883 13.6354 7.58342 13.7181 7.58342 13.8125C7.58342 13.9069 7.61883 13.9896 7.68967 14.0604C7.7605 14.1313 7.84314 14.1667 7.93758 14.1667ZM5.45842 16.2917C5.06883 16.2917 4.73521 16.1528 4.45754 15.8752C4.17987 15.5975 4.04128 15.2641 4.04175 14.875V2.125C4.04175 1.73542 4.18058 1.40179 4.45825 1.12412C4.73592 0.846458 5.0693 0.707861 5.45842 0.708333H12.5417C12.9313 0.708333 13.265 0.847167 13.5426 1.12483C13.8203 1.4025 13.9589 1.73589 13.9584 2.125V14.875C13.9584 15.2646 13.8196 15.5982 13.5419 15.8759C13.2643 16.1535 12.9309 16.2921 12.5417 16.2917H5.45842ZM5.45842 11.3333H12.5417V4.25H5.45842V11.3333Z"
              fill="#2B2B2B"
            />
          </svg>
          <span className="mb-[1px]">+977 985-1169602</span>
        </div>
        <div className="email flex items-center gap-1 border-l pl-5 border-slate-700 cursor-pointer">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 3H3C2.175 3 1.5075 3.675 1.5075 4.5L1.5 13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5C16.5 3.675 15.825 3 15 3ZM15 6L9 9.75L3 6V4.5L9 8.25L15 4.5V6Z"
              fill="#2B2B2B"
            />
          </svg>
          <span className="mb-[2px]">
            <Link
              to="https://www.google.com/intl/ne/gmail/about/#inbox/FMfcgxwGDNKsjSwJkvMTMTLKrjVksQzn?compose=GTvVlcSHxTbkPsqtBLfzcGRmnqCPxClmdGmxJtJfhxpcBkdNPtfHdkrnLKDBXgpGNKWvFZcwFjHxD"
              target="_blank"
            >
              {" "}
              info@utsahlearningcenter.com
            </Link>
          </span>
        </div>
        <div className="email flex items-center gap-1 cursor-pointer">
          <svg
            width="12"
            height="16"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 8C6.4125 8 6.76575 7.853 7.05975 7.559C7.35375 7.265 7.5005 6.912 7.5 6.5C7.5 6.0875 7.353 5.73425 7.059 5.44025C6.765 5.14625 6.412 4.9995 6 5C5.5875 5 5.23425 5.147 4.94025 5.441C4.64625 5.735 4.4995 6.088 4.5 6.5C4.5 6.9125 4.647 7.26575 4.941 7.55975C5.235 7.85375 5.588 8.0005 6 8ZM6 15.2188C5.9 15.2188 5.8 15.2 5.7 15.1625C5.6 15.125 5.5125 15.075 5.4375 15.0125C3.6125 13.4 2.25 11.903 1.35 10.5215C0.45 9.14 0 7.8495 0 6.65C0 4.775 0.60325 3.28125 1.80975 2.16875C3.01625 1.05625 4.413 0.5 6 0.5C7.5875 0.5 8.9845 1.05625 10.191 2.16875C11.3975 3.28125 12.0005 4.775 12 6.65C12 7.85 11.55 9.14075 10.65 10.5223C9.75 11.9037 8.3875 13.4005 6.5625 15.0125C6.4875 15.075 6.4 15.125 6.3 15.1625C6.2 15.2 6.1 15.2188 6 15.2188Z"
              fill="#2B2B2B"
            />
          </svg>

          <span className="mb-[1px]">Bahiti Marg, Sitapaila, Nepal</span>
        </div>
      </div>
    </>
  );
};

export default Uppnavv;
