import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProgramCards from "./ProgramCards";
import { data } from "../data/Program";

const Programcatd = () => {
  const { content } = useParams();
  const finalData = data.filter(
    (e) => e.url.toLowerCase() === content.toLowerCase()
  );

  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="mb-10">
      <ProgramCards data={finalData[0] && finalData[0]} />
    </div>
  );
};

export default Programcatd;
