import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const Contact = () => {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const [data, setData] = useState({
    name: "",
    email: "",
    number: "",
    msg: "",
  });

  const inpFldChang = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [error, setError] = useState({
    status: false,
    position: "",
    message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const serviceId = "service_8om30dk";
    const templateId = "template_kiybmvu";
    const userId = "-zOqJeIt4gRDF7kJn";

    if (data.name.length < 2) {
      setError({
        status: true,
        position: "name",
        message: "Name must be greater than 2 character",
      });
    } else if (data.email.length < 4) {
      setError({
        status: true,
        position: "email",
        message: "Email must be greater than 4 character",
      });
    } else if (data.number.length !== 10) {
      setError({
        status: true,
        position: "number",
        message: "Please enter 10 character phone number",
      });
    } else if (data.msg.length < 10) {
      setError({
        status: true,
        position: "msg",
        message: "Message must be greater than 10 character",
      });
    } else {
      setError({
        status: false,
        position: "",
        message: "",
      });
      emailjs.send(serviceId, templateId, data, userId).then(
        (response) => {
          // console.log("SUCCESS!", response.status, response.text);
          toast.success("Message send success");
          setData({ name: "", email: "", number: "", msg: "" });
        },
        (error) => {
          // console.log("FAILED...", error);
          toast.error("Sorry, please try again later");
        }
      );
    }
  };

  return (
    <>
      <div className="mb-18 flex flex-col">
        {/* // eslint-disable-next-line */}
        <div className="mt-14 w-full mb-8">
          <iframe
            src="https://maps.google.com/maps?q=utsah%20learning%20center&t=k&z=17&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            className="h-[50vh] lg:w-[70%] w-[90%] m-auto"
            title="google map"
          ></iframe>
        </div>

        <div className=" flex flex-col mb-36">
          <div className="heading flex justify-center items-center flex-col">
            <h2 className="text-center font-semibold text-2xl tracking-wide text-[#444444]">
              CONTACT US
            </h2>
            <div className="bg-gradient-to-r from-slate-200 via-black to-slate-200 h-[1px] w-[150px] mb-8"></div>
          </div>
          <div className="flex md:flex-row  md:justify-between flex-col-reverse md:w-[70%] w-[80%] m-auto">
            <div className="flex flex-col gap-5 italic text-[#5C5C5C] md:w-[40%] mt-20 lg:mt-0">
              <div className="flex flex-col gap-1 items-left">
                <h2 className="font-semibold lg:text-2xl text-xl">
                  {" "}
                  Corporate office:
                </h2>
                <p className="lg:text-xl ">Utsah Learning Center </p>
                <p className="lg:text-xl">Sitapaila, Kathmandu, Nepal 44600</p>
              </div>
              <div className="flex flex-col gap-1">
                <h2 className="font-semibold lg:text-2xl text-xl">
                  Office Phone:
                </h2>
                <p className="lg:text-xl">+977 98-51169602</p>
              </div>

              <div className="flex flex-col gap-1">
                <h2 className="font-semibold lg:text-2xl text-xl font-robo">
                  Email:
                </h2>
                <p className="lg:text-xl ">info@utsahlearningcenter.com</p>
              </div>
            </div>

            <div
              id="ContactForm"
              className="md:w-[60%] w-[100%] m-auto md:border-l border-[#5C5C5C] relative"
            >
              <form
                onSubmit={handleSubmit}
                className=" flex flex-col m-auto gap-5 mt-3 md:ml-6"
              >
                <div className="Contactsection w-full">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name*"
                    value={data.name}
                    onChange={inpFldChang}
                    className="border border-[#000000] py-2 w-[100%] m-auto p-2 px-3 italic bg-[#E0E0E0]"
                  />
                  {error.position === "name" && (
                    <p className="text-red-500 text-xs m-1">{error.message}</p>
                  )}
                </div>

                <div className="Contactsection">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={data.email}
                    onChange={inpFldChang}
                    placeholder="Email*"
                    className="border border-[#000000] py-2 w-[100%] m-auto p-2 px-3 italic bg-[#E0E0E0]"
                  />
                  {error.position === "email" && (
                    <p className="text-red-500 text-xs m-1">{error.message}</p>
                  )}
                </div>
                <div className="Contactsection">
                  <input
                    type="number"
                    name="number"
                    value={data.number}
                    onChange={inpFldChang}
                    id="mbnumber"
                    placeholder="Mobile*"
                    className="border border-[#000000] py-2 w-[100%] m-auto p-2 px-3 italic bg-[#E0E0E0]"
                  />
                  {error.position === "number" && (
                    <p className="text-red-500 text-xs m-1">{error.message}</p>
                  )}
                </div>

                <div className="Contactsection">
                  <textarea
                    name="msg"
                    id="message"
                    cols="30"
                    rows="7"
                    value={data.msg}
                    onChange={inpFldChang}
                    placeholder="Message*"
                    className="border border-[#000000] resize-none w-[100%] m-auto p-2 px-3 bottom-0 italic bg-[#E0E0E0]"
                  ></textarea>
                  {error.position === "msg" && (
                    <p className="text-red-500 text-xs m-1">{error.message}</p>
                  )}
                </div>
                <div className="md:bg-gradient-to-r from-[#E2E2E2] to-[#A0A0A0] h-[2px] w-[100%]"></div>
                <div className="Contactsection absolute right-0 md:-bottom-20 -bottom-10">
                  <button className="px-12 py-[7px] tracking-wider bg-gradient-to-r from-[#0808E1] to-[#000089] hover:from-[#000089] hover:to-[#000089] rounded-[6px] text-[#FFFFFF] text-lg">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
