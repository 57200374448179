import React, { useEffect } from "react";
import ImageSwiper from "./ImageSwiper";

export default function GalleryModal(props) {
  const { open, setOpen } = props;
  const [showModal, setShowModal] = React.useState(false);
  useEffect(() => {
    if (open) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [open]);

  const closeBtnClk = () => {
    setShowModal(false);
    setOpen(false);
  };
  return (
    <>
      <button
        className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hidden"
        type="button"
        onClick={() => setShowModal(true)}
      ></button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col min-h-[600px] min-w-[600px] bg-black outline-none focus:outline-none">
                <button
                  className="text-xl font-semibold absolute right-5 top-2 text-white z-50"
                  type="button"
                  onClick={closeBtnClk}
                >
                  X
                </button>
                <div className="w-full h-full bg-red-300 overflow-hidden">
                  <ImageSwiper />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
