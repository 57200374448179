import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AllAlbum from "./AllAlbum";

const Album = () => {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  return (
    <div className="mt-4">
      <div className="heading flex mb-5 justify-center items-center flex-col mt-10">
        <h2 className="text-center font-semibold text-2xl tracking-wide text-[#444444]">
          EVENT GALLERY
        </h2>
        <div className="bg-gradient-to-r from-slate-200 via-black to-slate-200 h-[1px] mt-1 w-[200px]"></div>
      </div>
      <AllAlbum />
    </div>
  );
};

export default Album;
