import React from 'react'
// import img1 from "../../../img/Tribhuvan_University.png";  
import img2 from "../../../img/ULC logo 3.png";

const ULCPartners = () => {
  return (
    <div className="min-h-[40vh] mb-20">
      <div className="min-h-[40vh] py-10 bg-[#E0E0E0]">
        <div className="heading flex mb-9 justify-center items-center flex-col mt-5">
          <h2 className="text-center font-semibold md:text-2xl text-xl tracking-wide text-[#404040]">
            Our Partners
          </h2>
          <div className="bg-gradient-to-r from-[#E0E0E0] via-[#414141] to-[#E0E0E0] h-[2px] w-[120px]"></div>
        </div>
        <div className="flex md:w-[72%] w-[100%] items-center justify-center m-auto gap-9 flex-col md:flex-row md:flex-wrap py-5">
          <img src={img2} alt="" className="md:w-1/3 w-[70%] max-h-[300px] max-w-[450px]" />
          {/* <img src={img1} alt="" className="md:w-1/3 w-[70%] max-h-[350px] max-w-[250px]" /> */}
          <img src={img2} alt="" className="md:w-1/3 w-[70%] max-h-[300px] max-w-[450px]" />
        </div>
      </div>
    </div>
  )
}

export default ULCPartners
