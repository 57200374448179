import React from "react";
import img1 from "../../../img/ULC logo.png";
// import img2 from "../../../img/tt.png";

const AboutPatners = () => {
  return (
    <div className="my-20 min-h-[50vh] h-full">
      <div className="heading flex mb-6 justify-center items-center flex-col ">
        <h2 className="text-center font-semibold text-2xl tracking-wide text-[#444444]">
          Our Partner’s
        </h2>
        <div className="bg-gradient-to-r from-slate-200 via-black to-slate-200 h-[2px] w-[120px]"></div>
      </div>
      <div className="w-full max-h-[400xp] h-full min-h-[300px] flex justify-around items-center md:flex-wrap flex-col md:flex-row gap-6">
        <div className="flex flex-col justify-center items-center gap-2 max-h-[220px] max-w-[250px]">
          <img src={img1} alt="" className="max-h-[220px] max-w-[250px]" />
          <h2 className="px-3 font-semibold tracking-wide">Utsah Learning Center</h2>
        </div>
        <div className="flex flex-col justify-center items-center gap-2 max-h-[220px] max-w-[250px]">
          <img src={img1} alt="" className="max-h-[220px] max-w-[250px]" />
          <h2 className="px-3 font-semibold tracking-wide">Utsah Learning Center</h2>
        </div>
        {/* <div className="flex flex-col justify-center items-center gap-2 max-h-[220px] max-w-[250px]">
          <img src={img2} alt="" className="max-h-[220px] max-w-[250px]" />
          <h2 className="px-3 font-semibold tracking-wide">Utsah Learning Center</h2>
        </div> */}
        <div className="flex flex-col justify-center items-center gap-2 max-h-[220px] max-w-[250px]">
          <img src={img1} alt="" className="max-h-[220px] max-w-[250px]" />
          <h2 className="px-3 font-semibold tracking-wide">Utsah Learning Center</h2>
        </div>
        <div className="flex flex-col justify-center items-center gap-2 max-h-[220px] max-w-[250px]">
          <img src={img1} alt="" className="max-h-[220px] max-w-[250px]" />
          <h2 className="px-3 font-semibold tracking-wide">Utsah Learning Center</h2>
        </div>
      </div>
    </div>
  );
};

export default AboutPatners;
