import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import About from "./About";
import Carousel from "./Carousel";
import ULCLife from "./ULCLife";
import ULCP from "./ULCP";
import ULCPartners from "./ULCPartners";

const Home = () => {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  return (
    <div className="bg-[#E0E0E0]">
      <Carousel />
      <About />
      <ULCP />
      <ULCLife />
      <ULCPartners />
    </div>
  );
};

export default Home;
