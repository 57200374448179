import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Carousel from "../Home/Carousel";
import AboutContent from "./AboutContent";
import AboutPatners from "./AboutPatners";

const About = () => {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 400, behavior: "smooth" });
  }, [location]);
  return (
    <div>
      <Carousel />
      <AboutContent />
      <AboutPatners />
    </div>
  );
};

export default About;
