import React from "react";
import { Link } from "react-router-dom";
const AlbumCards = (props) => {
  const { data } = props;
  return (
    <div
      className=" cursor-pointer overflow-hidden rounded-b-2xl shadow-md"
      key={data.id}
    >
      <div className="relative h-[320px] md:h-[310px] rounded-b-2xl">
        <Link to={`/event-gallery/album/${data.id}`}>
          <div className="!h-[200px] w-full bg-black relative flex justify-center items-center">
            <img
              className="opacity-60 relative duration-300 !h-[200px] w-full object-cover"
              src={data.image}
              alt={data.title}
            />
            <span className="absolute text-white text-xl tracking-wide">
              +{data.gallery.length}
            </span>
          </div>
          <div>
            <div className="flex justify-end m-1 mx-2 text-sm tracking-wide font-semibold">
              <div className="date">Date : {data.date}</div>
            </div>
            <div className="imgcontent flex justify-center items-center flex-col">
              <div className="content flex flex-col gap-1">
                <div className="img flex text-sm md:text-base justify-start gap-1 items-center">
                  <svg
                    width="16"
                    height="11"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.06 0H0.94C0.690697 0 0.451604 0.0990354 0.27532 0.27532C0.0990354 0.451604 0 0.690697 0 0.94V10.06C0 10.3093 0.0990354 10.5484 0.27532 10.7247C0.451604 10.901 0.690697 11 0.94 11H15.06C15.3093 11 15.5484 10.901 15.7247 10.7247C15.901 10.5484 16 10.3093 16 10.06V0.94C16 0.690697 15.901 0.451604 15.7247 0.27532C15.5484 0.0990354 15.3093 0 15.06 0ZM3.28 1.725C3.57667 1.725 3.86668 1.81297 4.11336 1.9778C4.36003 2.14262 4.55229 2.37689 4.66582 2.65097C4.77935 2.92506 4.80906 3.22666 4.75118 3.51764C4.6933 3.80861 4.55044 4.07588 4.34066 4.28566C4.13088 4.49544 3.86361 4.6383 3.57264 4.69618C3.28166 4.75406 2.98006 4.72435 2.70597 4.61082C2.43189 4.49729 2.19762 4.30503 2.0328 4.05836C1.86797 3.81168 1.78 3.52167 1.78 3.225C1.78 2.82718 1.93804 2.44564 2.21934 2.16434C2.50064 1.88304 2.88218 1.725 3.28 1.725ZM14 9H2L5.73 5.265C5.79649 5.19905 5.88635 5.16204 5.98 5.16204C6.07365 5.16204 6.16351 5.19905 6.23 5.265L8.07 7.105L10.605 4.5C10.6715 4.43405 10.7613 4.39704 10.855 4.39704C10.9487 4.39704 11.0385 4.43405 11.105 4.5L14 7.395V9Z"
                      fill="#444444"
                    />
                  </svg>
                  <span className="tracking-wide font-semibold">
                    {data.title}
                  </span>
                </div>
                <div className="alb flex justify-start gap-1 text-sm md:text-base items-center">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.25 0.6875V15.3125H6.4375V13.0625H7.5625V15.3125H13.75V0.6875H0.25ZM1.375 1.8125H12.625V14.1875H8.6875V11.9375H5.3125V14.1875H1.375V1.8125ZM2.5 2.9375V4.0625H4.75V2.9375H2.5ZM5.875 2.9375V4.0625H8.125V2.9375H5.875ZM9.25 2.9375V4.0625H11.5V2.9375H9.25ZM2.5 5.1875V6.3125H4.75V5.1875H2.5ZM5.875 5.1875V6.3125H8.125V5.1875H5.875ZM9.25 5.1875V6.3125H11.5V5.1875H9.25ZM2.5 7.4375V8.5625H4.75V7.4375H2.5ZM5.875 7.4375V8.5625H8.125V7.4375H5.875ZM9.25 7.4375V8.5625H11.5V7.4375H9.25ZM2.5 9.6875V10.8125H4.75V9.6875H2.5ZM5.875 9.6875V10.8125H8.125V9.6875H5.875ZM9.25 9.6875V10.8125H11.5V9.6875H9.25ZM2.5 11.9375V13.0625H4.75V11.9375H2.5ZM9.25 11.9375V13.0625H11.5V11.9375H9.25Z"
                      fill="#444444"
                    />
                  </svg>
                  <span>Utsah Learning Center</span>
                </div>
              </div>
              <div className="absolute bottom-2">
                <svg
                  width="46"
                  height="4"
                  viewBox="0 0 46 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="46" height="4" rx="2" fill="#979797" />
                </svg>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
};

export default AlbumCards;
