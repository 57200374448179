import React from "react";
import img from "../../../img/ULC logo.png";

const AboutContent = () => {
  return (
    <div className="min-h-[50vh] h-full py-8 rounded-md shadow-md md:w-[75%] w-[95%] m-auto mt-20 flex flex-col justify-center items-center bg-[#ffffff40]">
      <div className="heading flex mb-6 justify-center items-center flex-col">
        <h2 className="text-center font-semibold text-2xl tracking-wide text-[#444444]">
          ABOUT US
        </h2>
        <div className="bg-gradient-to-r from-slate-200 via-black to-slate-200 h-[2px] w-[120px]"></div>
      </div>
      <div className="flex h-full justify-around items-center px-5 md:px-10 flex-col lg:flex-row gap-4 lg:gap-0">
        <div className="lg:w-[40%] w-full">
          <img src={img} alt="" className="min-h-[300px]" />
        </div>
        <div className="w-full lg:w-[45%] text-justify">
          Utsah Learning Center, established in 2015 in kathmandu, is a research
          and consulting company that work in collaboration with various
          organizations and educational institution. We work in research
          projects, organize competitions, trainings, conferences and workshops.
          Utsah Learning Center, established in 2015 in kathmandu, is a research
          and consulting company that work in collaboration with various
          organizations and educational institution. We work in research
          projects, organize competitions, trainings, conferences and workshops.
          Utsah Learning Center, established in 2015 in kathmandu, is a research
          and consulting company that work in collaboration with various
          organizations and educational institution. We work in research
          projects, organize competitions, trainings, conferences and workshops.
          Utsah Learning Center, established in 2015 in kathmandu, is a research
          and consulting company that work in collaboration with various
          organizations and educational institution. We work in research
          projects, organize competitions, trainings, conferences and workshops.
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
