// import React from "react";
import img1 from "../../../img/School/01.jpg";
import img2 from "../../../img/School/02.jpg";
import img3 from "../../../img/others/15.JPG";
import img4 from "../../../img/others/16.JPG";
import img5 from "../../../img/others/17.JPG";
import img6 from "../../../img/others/18.JPG";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useState } from "react";

const ULCLife = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = [
    { id: 1, galleryImg: img1 },
    { id: 2, galleryImg: img2 },
    { id: 3, galleryImg: img3 },
    { id: 4, galleryImg: img4 },
    { id: 5, galleryImg: img5 },
    { id: 6, galleryImg: img6 },
  ];
  return (
    <div className="min-h-[40vh] mt-20 mb-20">
      <div className="min-h-[40vh] w-[100%] bg-[#414141] py-10">
        <div className="heading flex mb-9 justify-center items-center flex-col mt-5">
          <h2 className="text-center font-semibold text-2xl tracking-wide text-[#fff]">
            ULC Life's
          </h2>
          <div className="bg-gradient-to-r from-[#414141] via-[#fff] to-[#414141] h-[1px] w-[120px]"></div>
        </div>
        <div className="flex w-full items-center justify-center m-auto bg-[#2E2E2E3D] lg:w-[90%]">
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={0}
          slidesPerView={windowWidth > 700 ? 3 : 1}
          navigation
          pagination={{ clickable: true }}
        >
          {data.map((e) => {
            return (
              <SwiperSlide key={e.id}>
                <div
                  className={`!flex !justify-center !items-center h-[50vh] mx-12`}
                >
                  <img
                    src={e.galleryImg}
                    alt=""
                    className="my-auto select-none opacity-80 min-h-[220px] max-h-[250px] object-contain max-w-[280px] md:max-w-[300px] lg:max-w-[350px] "
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ULCLife;
