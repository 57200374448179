import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import GalleryModal from "./GalleryModal";
import { data } from "../data/Album";

const AllGallery = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  const fData = data.filter((e) => e.id === Number(id));

  return (
    <>
      <div className="bg-[#08080866] md:p-10 md:py-10 p-5">
        <div className="heading flex mb-5 justify-center items-center flex-col">
          <h2 className="text-center font-semibold text-2xl tracking-wide text-[#fff]">
            {fData[0] && fData[0].title}
          </h2>
          <div className="bg-gradient-to-r from-[#08080827] via-[#ededed] to-[#08080827] h-[1px] mt-1 w-[200px]"></div>
        </div>
        <div className="bg-[#262626] rounded-lg shadow-lg">
          <div className="max-w-[98%] mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {fData[0] &&
                fData[0].gallery.map((e) => {
                  return (
                    <Link
                      to={`/event-gallery/album/${
                        fData[0] && fData[0].id
                      }/image/${e.id}`}
                      key={e.id}
                    >
                      <img
                        src={e.galleryImg}
                        className="md:h-[250px] h-[200px] min-w-[200px] md:min-w-[250px] max-w-[300px] object-cover m-auto cursor-pointer"
                        alt=""
                      />
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <GalleryModal open={open} setOpen={setOpen} />
    </>
  );
};

export default AllGallery;
